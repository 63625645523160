import React from 'react'

const Map = () => {
  return (
   
     <div id="map"  style={{backgroundImage:'url( "img/bg/map.jpg")'}} >
        </div>
     
     
     
 
 
  )
}

export default Map